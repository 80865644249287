@import 'react-phone-number-input/style.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-toggle/style.css';
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';

.PhoneInputInput {
  height: 29px;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 5px;
}
.PhoneInputCountry {
  background-color: white;
  padding-right: 10px;
  margin-right: -10px;
  margin-top: 1px;
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-bottom: 1px;
}

#firebaseui_container {
  margin: 20px 0;
}
.firebaseui-card-actions {
  box-sizing: border-box !important;
}
