* {
  font-family: 'Roboto-Regular';
  box-sizing: border-box;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  float: none;
}

#northern-devs-link {
  position: absolute;
  top: -100000px;
  left: -1000000px;
  opacity: 0;
}

html,
body {
  background-color: transparent;
}
