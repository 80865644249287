:root {
  --theme-blue: #00aeef;
  --theme-orange: #f9a13a;
  --theme-green: #62bb46;
  --theme-grey: #5b6063;
  --theme-red: #ec3b3b;
  --theme-light-blue: #ddf6ff;
  --theme-light-orange: #ffe9d0;
  --theme-light-green: #e4f4df;
  --theme-light-grey: #eef2f3;
  --theme-background: '#1b1d1e';
}
